import React from 'react';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import scrollTo from 'gatsby-plugin-smoothscroll';

import Layout from '../components/layout';
import SEO from '../components/seo';

//components
import LocationCard from '../components/locationcard';
import settings from '../../settings';
import setBackgroundImage from "../helpers/setBackgroundImage";
import BmwPcLocations from '../components/bmwGlance/bmwPcLocations';
import VideoBackground from '../components/videoBackground';

//images
const topbannerMobile = `${settings.IMAGES_BASE_URL}/images/locations/bmw-m5-restyling-location-top-banner.jpg`;
const zentrum = `${settings.IMAGES_BASE_URL}/images/locations/bmw-zentrum-south-carolina.jpg`;
const factorytour = `${settings.IMAGES_BASE_URL}/images/locations/bmw-factory-tour.jpg`;
const joshuatree = `${settings.IMAGES_BASE_URL}/images/locations/thermal-performance-center-building.jpg`;
const golfcourse = `${settings.IMAGES_BASE_URL}/images/locations/palm-springs-golf-course.jpg`;
const spartanburgMobile = `${settings.IMAGES_BASE_URL}/images/locations/south-carolina-bmw-performance-center.jpg`;
const spartanburgTrackMobile = `${settings.IMAGES_BASE_URL}/images/locations/spartanburg-overhead-track-banner.jpg`;
const thermalMobile = `${settings.IMAGES_BASE_URL}/images/locations/thermal-california-bmw-performance-center-2022.jpg`;
const thermalTrackMobile = `${settings.IMAGES_BASE_URL}/images/locations/thermal-overhead-track-banner.jpg`;
const arrowLeft = `${settings.IMAGES_BASE_URL}/images/assets/arrowleft-white.svg`;
const indiana = `${settings.IMAGES_BASE_URL}/images/locations/indy_location.jpg`;
const inidanaSpeedWay = `${settings.IMAGES_BASE_URL}/images/locations/indy_track.jpg`;
const arrowRight = `${settings.IMAGES_BASE_URL}/images/assets/arrowright-white.svg`;
const historicSights =  `${settings.IMAGES_BASE_URL}/images/locations/indy_city.jpg`;
const restaurant =  `${settings.IMAGES_BASE_URL}/images/locations/indy_food.jpg`;
const videoUrl = `${settings.IMAGES_BASE_URL}/videos/locations.mp4`;

const PrevArrow = (props) => {
  const { className, onClick } = props
  return (
      <button
          className={className}
          onClick={onClick}
      >
         <LazyLoadImage effect="blur" src={arrowRight} alt="Slider Previous Arrow" />
      </button>
  );
}
const NextArrow = (props) => {
  const { className, onClick } = props
  return (
      <button
          className={className}
          onClick={onClick}
      >
         <LazyLoadImage effect="blur" src={arrowLeft} alt="Slider Next Arrow" />
      </button>
  );
}

class LocationPage extends React.Component {
  constructor(props) {
    super(props);

    this.contentRef = null;
  }

  state = {
    width: ''
  };

  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  scrollToContent = () => {
    if(!this.contentRef) return;

    this.contentRef.scrollIntoView({ behavior: "smooth" });
  };


  render() {
    const { width } = this.state;
    const isMobile = width < 1200;
    const isDesktop = width >= 1200;



    const settings = {
      dots: false,
      arrows: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <PrevArrow /> ,
      prevArrow: <NextArrow />
    };

    return (
      <Layout>
        <SEO title="BMW Driving School Locations | BMW Performance Driving School" 
          description="BMW driving experiences are available in South Carolina, California and the Indianapolis Motor Speedway." 
          keywords="bmw driving experience locations, bmw driving school greenville sc, bmw driving school near me, bmw driving school thermal, bmw driving school in south carolina, bmw driving school palm springs, bmw driving school experience"
        />
        <div className="page__locations">
          <VideoBackground
            url={videoUrl}
            poster={topbannerMobile}
            imageAlt="BMW M5 Location Top Banner"
            scrollTo={this.scrollToContent}
            topLeftChildren={
              <div className="text_left">
                <h1>YOU CAN CALL<br/>IT A VACATION.</h1>
              </div>
            }
          />

          <section className="location" ref={(ref) => this.contentRef = ref}>
            <div className="container">
              <div className="location__header">
                <h1>BMW DRIVING EXPERIENCE LOCATIONS</h1>
                <p>
                  Whichever BMW Driving School location you select, you’ll find beautiful 
                  surroundings, an attentive staff and of course, plenty of driving pleasure.
                </p>
                <div className="buttons">
                  <button className="btn btn-bordered" onClick={() => scrollTo('#southcarolina')}>
                    South Carolina
                  </button>
                  <button className="btn btn-bordered" onClick={() => scrollTo('#california')}>
                    California
                  </button>
                  <button className="btn btn-bordered" onClick={() => scrollTo('#indiana')}>
                    Indianapolis
                  </button>
                </div>
              </div>
            </div>
            {isMobile && (
              <React.Fragment>
                <div className="location__type">
                  <div className="location__mobile" id="southcarolina">
                    <LazyLoadImage effect="blur" src={spartanburgMobile} alt="Spartanburg, South Carolina" />
                    <div className="container">
                      <div className="location__mobile__content">
                        <h3>Spartanburg, South Carolina</h3>
                        <p>
                          Make a vacation out of your visit to the Performance Center in Greenville-Spartanburg. With
                          numerous hotels, nearby golf courses and charming downtown Greenville a short drive away,
                          there is plenty to explore when not behind the wheel of an Ultimate Driving Machine<sup>®</sup>.
                        </p>
                        <div className="buttons">
                          <a href="https://www.visitgreenvillesc.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">See Greenville</button>
                          </a>
                          <a href="https://www.visitspartanburg.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">See Spartanburg</button>
                          </a>
                          <a href="/hotelpartners/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">Hotel Partners</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="location__mobile">
                    <LazyLoadImage effect="blur" src={spartanburgTrackMobile} alt="The Track" />
                    <div className="container">
                      <h3>The Track</h3>
                      <p>
                        Fast turns, a back straight perfect for speed exercises and a polished wet concrete skid pad
                        make our Spartanburg track the perfect place to hone your driving skills.
                      </p>
                    </div>
                  </div>
                  <Slider {...settings}>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={zentrum} alt="BMW Zentrum South Carolina" />
                      <div className="container">
                        <h4>THE ZENTRUM</h4>
                        <p>
                          Explore BMW’s past while marveling at its future with a tour of the Zentrum Museum. Featuring
                          many of BMW’s most famous models, you’ll get up close with our history and what makes a BMW so
                          special. Open daily from 9 AM to 5:30 PM.
                        </p>
                        <a
                          href="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">Find out More</button>
                        </a>
                      </div>
                    </div>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={factorytour} alt="BMW Factory Tour" />
                      <div className="container">
                        <h4>FACTORY TOUR</h4>
                        <p>
                          Located just across the street from the Performance Center is our manufacturing plant for the X3, X4, X5, X6 and X7. Schedule a tour and watch in amazement as over 1,500 vehicles are produced each day. Please visit the plant website for more information.
                        </p>
                        <a
                          href="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">Find out More</button>
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
                <div className="location__type">
                  <div className="location__mobile" id="california">
                    <LazyLoadImage effect="blur" src={thermalMobile} alt="Thermal, California" />
                    <div className="container">
                      <div className="location__mobile__content">
                        <h3>Thermal, California</h3>
                        <p>
                          With its year-round warm, sunny weather and vibrant nightlife, Thermal offers something for
                          everyone to enjoy. The beautiful backdrop of snow-capped mountains prevail over much of the
                          area, and its close proximity to numerous hotels, golf courses and resort-like Palm Springs
                          offers a chance to relax.
                        </p>
                        <div className='buttons'>
                          <a href="https://www.visitgreaterpalmsprings.com/" rel="noopener noreferrer" target="_blank">
                            <button className="btn btn-bordered">Learn More</button>
                          </a>
                          <a href="/hotelpartners/"  target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">Hotel Partners</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="location__mobile">
                    <LazyLoadImage effect="blur" src={thermalTrackMobile} alt="The track at Thermal Club" />
                    <div className="container">
                      <h3>The Track at Thermal Club</h3>
                      <p>
                        Set against the backdrop of a beautiful mountain range, The Thermal 
                        Club is an exclusive race track location that offers three unique 
                        layouts, plus our own challenge course to encourage maximum thrills.
                      </p>
                    </div>
                  </div>
                  <Slider {...settings}>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={joshuatree} alt="Joshua Tree National Park" />
                      <div className="container">
                        <h4>Joshua tree</h4>
                        <p>
                          Experience amazing natural beauty at Joshua Tree National Park located just outside of Palm
                          Springs. Camp, hike, climb and take in a night sky like no other.
                        </p>
                        <a href="https://www.nps.gov/jotr/index.htm" target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-bordered">Learn More</button>
                        </a>
                      </div>
                    </div>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={golfcourse} alt="Palm Springs Golf Course" />
                      <div className="container">
                        <h4>GOLF</h4>
                        <p>
                          Home to some of the most beautiful courses in the world, Palm Springs has plenty to offer the
                          golfing enthusiast. You won’t know whether to swing or stare.
                        </p>
                        <a
                          href="https://www.visitgreaterpalmsprings.com/play/things-to-do/golf/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">Learn More</button>
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              </React.Fragment>
            )}
            {isDesktop && (
              <React.Fragment>
                <div className="location__type">
                  <div className="location__spartanburg" id="southcarolina">
                    <div className="container">
                      <div className="location__spartanburg__content">
                        <h3>Spartanburg, South Carolina</h3>
                        <p>
                          Make a vacation out of your visit to the Performance Center in Greenville-Spartanburg. With
                          numerous hotels, nearby golf courses and charming downtown Greenville a short drive away,
                          there is plenty to explore when not behind the wheel of an Ultimate Driving Machine<sup>®</sup>.
                        </p>
                        <div className="buttons">
                          <a href="https://www.visitgreenvillesc.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">See Greenville</button>
                          </a>
                          <a href="https://www.visitspartanburg.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">See Spartanburg</button>
                          </a>
                          <a href="/hotelpartners/"  target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">Hotel Partners</button>
                          </a>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="location__track location__track__spartanburg">
                    <div className="location__track__text">
                      <h3>The Track</h3>
                      <p>
                        Fast turns, a back straight perfect for speed exercises and a polished wet concrete skid pad
                        make our Spartanburg track the perfect place to hone your driving skills.
                      </p>
                    </div>
                  </div>
                  <div className="location__cards">
                    <div className="container">
                      <div className="row">
                        <LocationCard
                          image={zentrum}
                          imageAlt="BMW Zentrum South Carolina"
                          title="THE ZENTRUM"
                          text="Explore BMW’s past while marveling at its future  with a tour of the Zentrum Museum. Featuring many of BMW’s most famous models, you’ll get up close with our history and what makes a BMW so special. Open daily from 9 AM to 5:30 PM."
                          btnText="Find out More"
                          url="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
                        />
                        <LocationCard
                          image={factorytour}
                          imageAlt="BMW Factory Tour"
                          title="FACTORY TOUR"
                          text="Located just across the street from the Performance Center is our manufacturing plant for the X3, X4, X5, X6 and X7. Schedule a tour and watch in amazement as over 1,500 vehicles are produced each day. Please visit the plant website for more information."
                          btnText="Find out More"
                          url="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="location__type">
                  <div className="location__thermal" id="california" style={setBackgroundImage(thermalMobile)}>
                    <div className="container">
                      <div className="location__thermal__content">
                        <h3>Thermal, California</h3>
                        <p>
                          With its year-round warm, sunny weather and vibrant nightlife, Thermal offers something for
                          everyone to enjoy. The beautiful backdrop of snow-capped mountains prevail over much of the
                          area, and its close proximity to numerous hotels, golf courses and resort-like Palm Springs
                          offers a chance to relax.
                        </p>
                        <div className="content__buttons">
                          <a href="https://www.visitgreaterpalmsprings.com/" target="_blank" rel="noopener noreferrer">
                            Learn More
                          </a>
                          <a href="/hotelpartners/"  target="_blank" rel="noopener noreferrer">
                            Hotel Partners
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="location__track location__track__thermal">
                    <div className="location__track__text">
                      <h3>The Track at Thermal Club</h3>
                      <p>
                        Set against the backdrop of a beautiful mountain range, The Thermal 
                        Club is an exclusive race track location that offers three unique 
                        layouts, plus our own challenge course to encourage maximum thrills.
                      </p>
                    </div>
                  </div>
                  <div className="location__cards">
                    <div className="container">
                      <div className="row">
                        <LocationCard
                          image={joshuatree}
                          imageAlt="Joshua Tree National Park"
                          title="Joshua tree"
                          text="Experience amazing natural beauty at Joshua Tree National Park located just outside of Palm Springs. Camp, hike, climb and take in a night sky like no other."
                          btnText="Learn More"
                          url="https://www.nps.gov/jotr/index.htm"
                        />
                        <LocationCard
                          image={golfcourse}
                          imageAlt="Palm Springs Golf Course"
                          title="GOLF"
                          text="Home to some of the most beautiful courses in the world, Palm Springs has plenty to offer the golfing enthusiast. You won’t know whether to swing or stare."
                          btnText="Learn More"
                          url="https://www.visitgreaterpalmsprings.com/play/things-to-do/golf/"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          {isMobile && (
              <React.Fragment>
                <div className="location__type">
                  <div className="location__mobile" id="indiana">
                    <LazyLoadImage effect="blur" src={indiana} alt="Indianapolis, Indiana" />
                    <div className="container">
                      <div className="location__mobile__content">
                        <h3>Indianapolis, Indiana</h3>
                        <p>
                          A beautiful and historic city, Indianapolis offers plenty of local 
                          eateries, breweries and family attractions to make your stay at 
                          this driving school location even more memorable.
                        </p>
                        <div className="buttons">
                        <a href="https://www.visitindy.com/" target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-bordered">Learn More</button>
                        </a>
                        <a href="/hotelpartners/"  target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-bordered">Hotel Partners</button>
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="location__mobile">
                    <LazyLoadImage effect="blur" src={inidanaSpeedWay} alt="The track at Thermal Club" />
                    <div className="container">
                      <h3>Indy Motor Speedway</h3>
                      <p>
                      The third-oldest permanent automobile race track in the world, Indy has hosted everything from NASCAR to F1 In its over 100-year history. You’ll never experience another track like it.
                      </p>
                    </div>
                  </div>
                  <Slider {...settings}>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={historicSights} alt="HISTORIC SIGHTS" />
                      <div className="container">
                        <h4>HISTORIC SIGHTS</h4>
                        <p>
                         There are dozens of historical districts with unique experiences in the city, from children’s museums to Art Deco palaces.
                        </p>
                        <a href="https://www.visitindy.com/indianapolis-history-buffs" target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-bordered">Learn More</button>
                        </a>
                      </div>
                    </div>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={restaurant} alt="RESTAURANTS AND BREWERIES" />
                      <div className="container">
                        <h4>RESTAURANTS AND BREWERIES</h4>
                        <p>
                        Foodies rejoice. Indy has dozens of restaurants and breweries that offer special takes on local and international cuisine. A day of speed will make you hungry.
                        </p>
                        <a
                          href="https://www.visitindy.com/indianapolis-where-to-eat"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">Learn More</button>
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              </React.Fragment>
            )}
            {isDesktop &&
              <div className="location__type">
                <div className="location__spartanburg location__indiana" id="indiana" style={setBackgroundImage(indiana)}>
                  <div className="container">
                    <div className="location__spartanburg__content">
                      <h3>Indianapolis, Indiana</h3>
                      <p>
                        A beautiful and historic city, Indianapolis offers plenty of local 
                        eateries, breweries and family attractions to make your stay at this 
                        driving school location even more memorable.
                      </p>
                      <div className="content__buttons">
                      <a href="https://www.visitindy.com/" target="_blank" rel="noopener noreferrer">
                            Learn More
                       </a>
                       <a href="/hotelpartners/"  target="_blank" rel="noopener noreferrer">
                           Hotel Partners
                      </a>
                     </div>
                    </div>
                  </div>
                </div>
                <div className="location__track" style={setBackgroundImage(inidanaSpeedWay)} >
                  <div className="location__track__text">
                    <h3>Indy Motor Speedway</h3>
                    <p>
                      The third-oldest permanent automobile race track in the world, Indy has hosted everything from NASCAR to F1 In its over 100-year history. You’ll never experience another track like it.
                    </p>
                  </div>
                </div>
                <div className="location__cards">
                    <div className="container">
                      <div className="row">
                        <LocationCard
                          image={historicSights}
                          imageAlt="HISTORIC SIGHTS"
                          title="HISTORIC SIGHTS"
                          text="There are dozens of historical districts with unique experiences in the city, from children’s museums to Art Deco palaces."
                          btnText="Learn More"
                          url="https://www.visitindy.com/indianapolis-history-buffs"
                        />
                        <LocationCard
                          image={restaurant}
                          imageAlt="RESTAURANTS AND BREWERIES"
                          title="RESTAURANTS AND BREWERIES"
                          text="Foodies rejoice. Indy has dozens of restaurants and breweries that offer special takes on local and international cuisine. A day of speed will make you hungry."
                          btnText="Learn More"
                          url="https://www.visitindy.com/indianapolis-where-to-eat"
                        />
                      </div>
                    </div>
                  </div>
              </div>
            }
            <BmwPcLocations/>
          </section>
        </div>
      </Layout>
    );
  }
}
export default LocationPage;
